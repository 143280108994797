export default function Header() {
  return  (
    <>
      <header>
        <ul>
          <li>
            <img
              src="https://images.pexels.com/users/avatars/116286/buenosia-carol-263.jpeg?auto=compress&fit=crop&h=130&w=130&dpr=1"
              alt="cats coming"
            />
            <br/>
            <br/>
            <span>Pastry Bakery</span>
            <br />
            Good Pastry for a good day
          </li>
        </ul>
      </header>
    </>
  );
}
